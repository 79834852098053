<template>
    <section class="white">
        <div class="form">
            <h3>{{ $t('eventsTitle') }}</h3>
            <p>{{ $t('eventsSubtitle') }}</p>
            <div class="fieldset">
                <div class="select">
                    <div class="label">
                        {{ $t('format') }}
                    </div>
                    <SelectField v-model="format"
                        :options="formatOptions"
                        :placeholder="$t('applicationSelect')"
                    />
                </div>
                <div class="select">
                    <div class="label">
                        {{ $t('targetGroup') }}
                    </div>
                    <SelectField v-model="targetGroup"
                        :options="targetGroupOptions"
                        :placeholder="$t('applicationSelect')"
                    />
                </div>
            </div>
            <div class="buttonset">
                <a>
                    <button class="button-dark reset" v-on:click="resetFilters">
                        {{ $t('resetFilters') }}
                    </button>
                </a>
                <a>
                    <button class="button-dark apply" v-on:click="applyFilters">
                        {{ $t('applyFilters') }}
                    </button>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    import { eventFormatsQuery, eventTargetGroupsQuery } from '@/queries'
    import SelectField from '@/components/Form/SelectField'
    import { EventBus } from '@/event-bus'
    import { ResetEventFilters, ApplyEventFilters } from '@/events'

    export default {
        name: 'EventFilters',

        components: {
            SelectField
        },

        data() {
            return {
                eventFormats: null,
                eventTargetGroups: null,
                format: this.$route.query.format ? this.$route.query.format : null,
                targetGroup: this.$route.query.targetGroup ? this.$route.query.targetGroup : null
            }
        },

        apollo: {
            eventFormats: eventFormatsQuery,
            eventTargetGroups: eventTargetGroupsQuery
        },

        computed: {
            formatOptions() {
                var self = this
                var formats = []
                if(this.eventFormats){
                    self.eventFormats.data.forEach(function(format){
                        formats.push({
                            label: self.localize(format.attributes.translatedName),
                            value: format.attributes.name
                        })
                    })
                }
                return formats
            },

            targetGroupOptions() {
                var self = this
                var targetGroups = []
                if(this.eventTargetGroups){
                    self.eventTargetGroups.data.forEach(function(targetGroup){
                        targetGroups.push({
                            label: self.localize(targetGroup.attributes.translatedName),
                            value: targetGroup.attributes.name
                        })
                    })
                }
                return targetGroups
            }
        },

        methods: {
            resetFilters() {
                this.format = null
                this.targetGroup = null
                EventBus.publish(new ResetEventFilters())
            },

            applyFilters() {
                EventBus.publish(new ApplyEventFilters(this.format, this.targetGroup))
            }
        }
    }
</script>

<style lang="scss" scoped>
    section {
        padding: calc(#{getSpacing('section-top-bottom', 'desktop')} - 25px) 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include breakpoint('tablet') {
            padding: getSpacing('section-top-bottom', 'tablet') 0;
        }
        @include breakpoint('mobile') {
            padding: getSpacing('section-top-bottom', 'mobile') 0;
        }

        .form {
            max-width: 1560px;
            margin: 0 auto;
            padding-bottom: 1px;

            @include breakpointAbove('max-width') {
                margin: 0 getSpacing('section-left-right', 'desktop');
                width: 1560px;
            }
            @include breakpoint('max-width') {
                margin: 0 getSpacing('section-left-right', 'desktop');
                width: calc(100% - #{getSpacing('section-left-right', 'desktop')})
            }
            @include breakpoint('tablet') {
                margin: 0 getSpacing('section-left-right', 'tablet');
                width: calc(100% - #{getSpacing('section-left-right', 'tablet')})
            }
            @include breakpoint('mobile') {
                margin: 0 getSpacing('section-left-right', 'mobile');
                width: calc(100% - #{getSpacing('section-left-right', 'mobile')})
            }
        }

        .buttonset {
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpoint('mobile') {
                flex-direction: column;
            }

            button {
                margin: 10px 25px;
            }

            .apply {
                background-color: $timberGreen;
                color: white;
            }
        }

        .fieldset {
            margin-top: 50px;
        }

        .select {
            width: calc(50% - 25px);

            @include breakpoint('mobile') {
                margin-top: 30px;
                width: 100%;

                &:first-child {
                    margin-top: unset;
                }
            }

            .label {
                font-size: getFontSize('smallText', 'desktop');
                font-family: getFontFamily('smallText');
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            .field {
                width: 100%;
            }
        }
    }
</style>
