<template>
    <div class="component-container white">
        <div class="event-list">
            <EventOverview v-for="event in events" :key="event.id" :event="event"/>
        </div>
    </div>
</template>

<script>
import EventOverview from './Elements/EventOverview.vue';

export default {
    name: 'EventList',

    props: [
        'events'
    ],

    components: {
        EventOverview
    },

    computed: {

    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .event-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        max-width: $container-width;
        margin: 0 auto;
    }

    padding-bottom: 64px;

}
</style>
