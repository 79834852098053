<template>
    <div class="event-card">
        <div class="inner-wrap">
            <div class="image">
                <picture v-if="this.srcsets">
                    <template v-for="[format, set] of Object.entries(srcsets)">
                        <source v-if="format !== originalFormat" :srcset="set" sizes="(max-width: 1100px) 80vw,15vw"
                            :type="'image/' + format.slice(1)">
                    </template>
                    <img loading="lazy" decoding="async" :srcset="srcsets[originalFormat]"
                        :type="'image/' + originalFormat.slice(1)" sizes="(max-width: 1100px) 80vw,5vw"
                        :alt="altText" width="100%">
                </picture>
            </div>

            <div class="content">
                <router-link :to="`/events/${event.attributes.urlKey}`" class="title">{{ localize(event.attributes.title) }}</router-link>

                <div class="details">
                    <img src="@/assets/icons/calendar.svg" class="icon"/> <b>{{ dateFormatLong(this.event.attributes.date) }}</b> | <img src="@/assets/icons/time.svg" class="icon"/> <b>{{ timeFormat(this.event.attributes.startTime) + '–' + timeFormat(this.event.attributes.endTime) + ' ' + this.$t('oclock') }}</b> | <img src="@/assets/icons/location.svg" class="icon"/><b>{{ this.localize(this.event.attributes.place) }}</b><br>
                    <template v-if="(this.localize(this.event.attributes.eventFormat.data.attributes.translatedName) !== 'Event')">
                        Format: {{ this.localize(this.event.attributes.eventFormat.data.attributes.translatedName) }}<br>
                    </template>
                    
                    Inhalt: {{ this.localize(this.event.attributes.content) }}<br>
                    Zielgruppe: {{ this.localize(this.event.attributes.eventTargetGroup.data.attributes.translatedName) }} 
                </div>

            </div>
            <div class="cta">
                <router-link :to="`/events/${event.attributes.urlKey}`">
                    <button class="button-dark">
                        {{ $t('visitEvent') }}
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { dateFormatLong, timeFormat } from '@/helper'

export default {
    name: 'EventOverview',

    props: [
        'event',
    ],

    components: {
    },

    computed: {
        altText() {
            if (this.localize(this.event.attributes.image) && this.localize(this.event.attributes.image).data){
                return this.localize(this.event.attributes.image).data.attributes.alternativeText
            }
            return "Event Title Image"
        },
        srcsets() {
            if (this.localize(this.event.attributes.image) && this.localize(this.event.attributes.image).data) { 
                return this.getImageSrcsets(this.localize(this.event.attributes.image).data.attributes)
            }
            return this.getPlaceholderSrcsets()
        },

        hasWebp() {
            return this.srcsets['.webp'] ? true : false
        },

        originalFormat() {
            let originalFormat = "";
            for (const [format, _] of Object.entries(this.srcsets)) {
                if (format !== '.webp') {
                    originalFormat = format
                }
            }

            return originalFormat
        },
    },

    methods: {
        dateFormatLong: dateFormatLong,
        timeFormat: timeFormat,
    }
}
</script>

<style lang="scss" scoped>
.event-card {
    background: $lightGrey;
    width: 100%;
    margin: 0px getSpacing('padding-default-horizontal', 'desktop');
    
    @include breakpoint('tablet') {
        margin: 0px getSpacing('padding-default-horizontal', 'tablet');
    }

    @include breakpoint('mobile') {
        margin: 0px getSpacing('padding-default-horizontal', 'mobile');
    }

    .inner-wrap {
        display: flex;
        flex-direction: row;
        min-height: 150px;

        @include breakpoint('mobile') {
            flex-direction: column;
        }

        .image {
            flex: 0 0 auto;
            width: 265px;
            min-height: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            @include breakpoint('mobile') {
                width: 100%;
            }
        }

        .content {
            flex: 1 1 auto;
            margin: 1rem 2rem;

            @include breakpoint('mobile') {
                margin: 0.5rem 1rem;
            }

            .title {
                margin-bottom: 0.5rem;

                font-size: getFontSize('eventOverviewTitle', 'desktop');
                font-family: getFontFamily('eventOverviewTitle');

                @include breakpoint('tablet') {
                    font-size: getFontSize('eventOverviewTitle', 'tablet');
                }

                @include breakpoint('mobile') {
                    font-size: getFontSize('eventOverviewTitle', 'mobile');
                }
            }

            .details {
                font-size: getFontSize('eventOverviewContent', 'desktop');
                @include breakpoint('tablet') {
                    font-size: getFontSize('eventOverviewContent', 'tablet');
                }
                @include breakpoint('mobile') {
                    font-size: getFontSize('eventOverviewContent', 'mobile');
                }

                .icon {
                    display: inline-block;
                    vertical-align: middle;
                    height: calc(getFontSize('eventOverviewTitle', 'desktop') - 5px);
                }
            }
        }


        .cta {
            flex: 0 0 auto;
            align-self: center;
            margin: 0 2rem;

            @include breakpoint('mobile') {
                width: 100%;
                display: flex;

                a {
                    flex-grow: 1;
                    display: flex;
                }
            }
            

            button {
                min-width: 150px;
                margin: unset;
                
                @include breakpoint('mobile') {
                    flex-grow: 1;
                    margin: 0.5rem 1rem;
                }
            }
        }
    }
}
</style>
