<template>
    <div>
        <div v-for="cmsBlock in cmsBlocks" :key="cmsBlock.__typename + cmsBlock.id">
            <component :is="componentMap[cmsBlock.__typename]" :cmsBlock="cmsBlock"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageBuilder',

        props: [
            'cmsBlocks'
        ],

        data() {
            return {
                componentMap: {
                    ComponentPageBuilderCounterBlock: () => import('@/components/PageBuilder/CounterBlock'),
                    ComponentPageBuilderSplitColumns: () => import('@/components/PageBuilder/SplitColumns'),
                    ComponentPageBuilderGraphicColumns: () => import('@/components/PageBuilder/GraphicColumnsBlock'),
                    ComponentPageBuilderHeaderBlock: () => import('@/components/PageBuilder/HeaderBlock'),
                    ComponentPageBuilderImageBlock: () => import('@/components/PageBuilder/ImageBlock'),
                    ComponentPageBuilderNamedListBlock: () => import('@/components/PageBuilder/NamedListBlock'),
                    ComponentPageBuilderQuoteBlock: () => import('@/components/PageBuilder/QuoteBlock'),
                    ComponentPageBuilderWysiwyg: () => import('@/components/PageBuilder/TextBlock'),
                    ComponentPageBuilderTextBlockWithButton: () => import('@/components/PageBuilder/TextBlockWithButton'),
                    ComponentPageBuilderTextBlockWithIcons: () => import('@/components/PageBuilder/TextBlockWithIcons'),
                    ComponentPageBuilderSeparator: () => import('@/components/PageBuilder/Separator'),
                    ComponentPageBuilderStartupSlider: () => import('@/components/PageBuilder/StartupSlider'),
                    ComponentPageBuilderTextColumns: () => import('@/components/PageBuilder/TextColumns'),
                    ComponentSliderSlider: () => import('@/components/PageBuilder/Slider'),
                    ComponentPageBuilderPartnersBlock: () => import('@/components/PageBuilder/PartnersBlock'),
                    ComponentPageBuilderTeamMembersBlock: () => import('@/components/PageBuilder/TeamMembers'),
                    ComponentPageBuilderSimplePodcastBlock: () => import('@/components/PageBuilder/SimplePodcastBlock'),
                    ComponentPageBuilderDetailedPodcastBlock: () => import('@/components/PageBuilder/DetailedPodcastBlock'),
                    ComponentPageBuilderMultiplePodcastsBlock: () => import('@/components/PageBuilder/MultiplePodcastsBlock'),
                    ComponentPageBuilderTextTiles: () => import('@/components/PageBuilder/TextTiles'),
                    ComponentPageBuilderGraphicTiles: () => import('@/components/PageBuilder/GraphicTiles'),
                    ComponentPageBuilderTiledMenu: () => import('@/components/PageBuilder/TiledMenu'),
                    ComponentPageBuilderEcosystemAnimation: () => import('@/components/PageBuilder/EcosystemAnimation'),
                    ComponentPageBuilderImageGallery: () => import('@/components/PageBuilder/ImageGallery'),
                    ComponentPageBuilderReachMemberForm: () => import('@/components/PageBuilder/ReachMemberForm'),
                    ComponentPageBuilderYouTube: () => import('@/components/PageBuilder/YouTube'),
                    ComponentPageBuilderSpacerBlock: () => import('@/components/PageBuilder/SpacerBlock')
                },
                components: {}
            }
        },

        mounted() {
            for (const [key, value] of Object.entries(this.componentMap)) {
                value().then(module => {
                    this.$set(this.components, key, module.default);
                });
            }
        }
    }
</script>
