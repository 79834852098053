<template>
    <div>
        <div class="entry-short" v-bind:style="{ backgroundImage: 'url(' + cmsPageHeaderImage + ')' }">
            <div v-if="cmsPage" class="context">
                <h1 v-if="cmsPage.data.attributes.title">{{ localize(cmsPage.data.attributes.title) }}</h1>
                <h2 v-if="cmsPage.data.attributes.subtitle">{{ localize(cmsPage.data.attributes.subtitle) }}</h2>
            </div>
        </div>

        <PageBuilder v-if="cmsPage" :cmsBlocks="cmsPage.data.attributes.cmsBlocks" />

        <div class="component-container white">
            <h3 class="white left">{{ $t('upcomingEvents') }}</h3>
            <EventList v-if="events" :events="events.data" />
            <section v-else class="white">
                <h4>{{ $t('noEventsFound') }}</h4>
            </section>

            <h3 class="white left">{{ $t('pastEvents') }}</h3>
            <EventList v-if="pastEvents" :events="pastEvents.data" />
            <section v-else class="white">
                <h4>{{ $t('noEventsFound') }}</h4>
            </section>
        </div>
    </div>
</template>

<script>
import PageBuilder from '@/components/PageBuilder'
import EventFilters from '@/components/EventFilters'
import Pagination from '@/components/Pagination'
import AlternatingList from '@/components/AlternatingList'
import EventList from '@/components/PageBuilder/EventList'
import { ResetEventFilters, ApplyEventFilters } from '@/events'
import {
    eventsCmsPageQuery,
    totalEventsQuery,
    eventsQuery,
    pastEventsQuery,
    globalOptionQuery
} from '@/queries'

export default {
    name: 'Events',

    components: {
        PageBuilder,
        EventFilters,
        Pagination,
        AlternatingList,
        EventList
    },

    data() {
        return {
            cmsPage: null,
            totalEvents: 0,
            events: null,
            pastEvents: null,
        }
    },

    apollo: {
        cmsPage: {
            query: eventsCmsPageQuery,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.eventList
        },

        totalEvents: {
            query: totalEventsQuery,
            variables() {
                return this.eventsVariables
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.events.meta.pagination.total
        },

        events: {
            query: eventsQuery,
            variables() {
                return {
                    date: new Date().toISOString().split('T')[0],
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.events
        },

        pastEvents: {
            query: pastEventsQuery,
            variables() {
                return {
                    date: new Date().toISOString().split('T')[0],
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.events
        },

        defaultCmsPageHeader: {
            query: globalOptionQuery,
            variables: {
                optionId: 'default-cms-page-header'
            },
            fetchPolicy: 'cache-first',
            update: data => data.globalOptions.data[0]
        }
    },

    computed: {
        eventsVariables() {
            var variables = {
                limit: this.limit,
                start: this.start,
            }
            if (this.format) {
                variables.format = this.format
            }
            if (this.targetGroup) {
                variables.targetGroup = this.targetGroup
            }
            return variables
        },

        total() {
            return Math.ceil(this.totalEvents / this.limit)
        },

        cmsPageHeaderImage() {
            return this.defaultCmsPageHeader ? this.getImageUrl(this.localize(this.defaultCmsPageHeader.attributes.image).data.attributes.url) : ''
        },
    },

    methods: {
        url(event) {
            return '/events/' + event.attributes.urlKey
        }
    }
}
</script>

<style lang="scss" scoped>
.component-container {
    max-width: $container-width;
    margin: 0 auto;

    .left {
        padding: getSpacing('padding-small-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');
        text-align: left;

        @include breakpoint('tablet') {
            padding: getSpacing('padding-small-vertical', 'tablet') getSpacing('padding-default-horizontal', 'tablet');
        }
        @include breakpoint('mobile') {
            padding: getSpacing('padding-small-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
        }
    }
}
</style>
