import { render, staticRenderFns } from "./EventFilters.vue?vue&type=template&id=2c9d8874&scoped=true"
import script from "./EventFilters.vue?vue&type=script&lang=js"
export * from "./EventFilters.vue?vue&type=script&lang=js"
import style0 from "./EventFilters.vue?vue&type=style&index=0&id=2c9d8874&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9d8874",
  null
  
)

export default component.exports